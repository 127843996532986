// src/components/GoogleLoginButton.js
import React from 'react';
import { GoogleLogin } from '@react-oauth/google';
import { useNavigate } from 'react-router-dom';

const GoogleLoginButton = () => {
    const navigate = useNavigate();

    const handleSuccess = async (response) => {
        const { credential } = response;

        // Perform the initial Google OAuth authentication
        const authResponse = await fetch(`${process.env.REACT_APP_API_URL}/api/google`, {
            method: 'GET',
            headers: {
                'Authorization': `Bearer ${credential}`
            }
        });

        if (authResponse.ok) {
            const { token } = await authResponse.json();
            localStorage.setItem('token', token);
            navigate('/dashboard');
        } else {
            console.error('Google authentication failed');
        }
    };

    const handleFailure = (response) => {
        console.error('Google login failed', response);
    };

    return (
        <GoogleLogin
            onSuccess={handleSuccess}
            onError={handleFailure}
        />
    );
};

export default GoogleLoginButton;