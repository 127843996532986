// src/pages/Dashboard.js
import React from 'react';

const Dashboard = () => {
  return (
    <div className="dashboard">
      <h1>Welcome</h1>
    </div>
  );
};

export default Dashboard;